
import {Component, Mixins, Prop} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {VekaletUcretDonemEntity} from "@/entity/VekaletUcretDonemEntity";
import MuvekkilVekaletUcretleriForm from "@/components/forms/MuvekkilVekaletUcretleriForm.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {VekaletEntity} from "@/entity/VekaletEntity";
import TutarAraligiForm from "@/components/forms/TutarAraligiForm.vue";

@Component({
  components: {TutarAraligiForm, MuvekkilVekaletUcretleriForm, FormDialog, DeleteDialog},
})
export default class MuvekkilVekaletUcretleriList extends Mixins(ObjectInputMixin) {
  @Prop() vekalet!: VekaletEntity;

  items: VekaletUcretDonemEntity[] = [];
  selectedItem: VekaletUcretDonemEntity = new VekaletUcretDonemEntity();
  headers = [
    {text: "Alt Sınır", value: "alt_sinir", align: "start", class: 'my-header-style'},
    {text: "Üst Sınır", value: "ust_sinir", align: "start", class: 'my-header-style'},
    {text: "Oran", value: "muvekkil_oran", align: "start", class: 'my-header-style'},
    {text: "Tutar", value: "standart_ucret", align: "start", class: 'my-header-style'},
    {text: "İşlemler", value: "actions", width: "100", align: "end", sortable: false, class: 'header-islemler-style'}
  ];

  mounted() {
    this.loadVekaletUcretleri();
  }

  load() {
    this.$emit("load");
  }

  loadVekaletUcretleri() {
    this.items = this.vekalet.vekalet_ucret.donemler;
    this.items.reverse();
    this.selectedItem = this.items[0];

    this.items.forEach((donem, index) => {
      donem.text = donem.baslangic_tarihi ? this.$helper.tarihStr(donem.baslangic_tarihi) : "";
      donem.oranlar?.forEach((oran, sayi) => {
        let donem = this.items[index]
        if (donem && donem.oranlar) {
          if (donem.oranlar[sayi + 1] && donem.oranlar[sayi] && sayi < donem.oranlar.length - 1) {
            donem.oranlar[sayi].ust_sinir = donem.oranlar[sayi + 1].alt_sinir - 1;
          }
        }
      });
    });
  }
}
