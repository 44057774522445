
import {Component, Vue} from "vue-property-decorator";
import MuvekkilList from "@/components/lists/MuvekkilList.vue";
import VekaletInfo from "@/components/infos/VekaletInfo.vue";
import AvukatPicker from "@/components/pickers/AvukatPicker.vue";
import AvukatList from "@/components/lists/AvukatList.vue";
import BankaBilgileriForm from "@/components/forms/BankaBilgileriForm.vue";
import BankaHesabiList from "@/components/lists/BankaHesabiList.vue";
import VekaletBankaHesabiSec from "@/components/lists/VekaletBankaHesabiSec.vue";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {AxiosResponse} from "axios";
import {CariKayitEntity} from "@/entity/CariKayitEntity";
import MuvekkilVekaletUcretleriList from "@/components/lists/MuvekkilVekaletUcretleriList.vue";
import DokumanList from "@/components/lists/DokumanList.vue";

@Component({
  components: {
    DokumanList,
    MuvekkilVekaletUcretleriList,
    VekaletBankaHesabiSec,
    BankaHesabiList,
    BankaBilgileriForm,
    AvukatList,
    AvukatPicker,
    MuvekkilList,
    VekaletInfo
  }
})
export default class VekaletDetayView extends Vue {
  item: VekaletEntity = new VekaletEntity();
  cariKayitlar: Array<CariKayitEntity> = [];

  mounted() {
    let activeProcess = {id: this.$route.params.id, key: 'vekalet-detay'}
    this.$store.commit('setActiveProcess', activeProcess);
    this.load();
  }

  load() {
    let index: number = parseInt(this.$route.params.id);
    this.$http
        .get('/api/v1/vekalet/' + index)
        .then((response: AxiosResponse<VekaletEntity>) => {
          this.item = response.data;
        });
  }
}
