

import {Component, Prop, Vue} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import MuvekkilForm from "@/components/forms/MuvekkilForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
import {ProgramPaths} from "@/enum/ProgramPaths";

@Component({
  components: {FormDialog, MuvekkilForm, DeleteDialog}
})

export default class MuvekkilList extends Vue {
  @Prop({default: []}) items!: Array<MuvekkilEntity>;
  @Prop({required: true}) vekaletId!: number;

  headers = [
    {text: "Adı", align: "start", value: "ad", class: 'my-header-style'},
    {text: "Vergi / TC Kimlik No", value: "no", class: 'my-header-style'},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align: 'end', class: 'header-islemler-style'}
  ];

  load() {
    this.$emit("load");
  }

  detay(item: MuvekkilEntity) {
    this.$router.push(ProgramPaths.ofispro + '/muvekkil/' + item.id);
  }
}
